<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #card>
        <div class="text-center">
          <div>
            <KiteIcon />
          </div>
          <div class="mt-3 mb-1">
            <h1>Confirm Email</h1>
          </div>
          <div>
            <p>An email has been sent to <span class="link">{{ registeredEmail }}.</span>
              Please check for an email from us and click on the included
              link to activate your account
            </p>
          </div>
          <div v-if="!resent_account_confirmation_email">
            <p>Email not received? <span class="link" @click="resendAccountConfirmationEmail"> resend now</span></p>
          </div>
          <p class="text-center mt-2">
            <router-link @click="goToHome">
              <feather-icon icon="ChevronLeftIcon" />
              Back to login
            </router-link>
          </p>
        </div>
      </template>
    </AuthWrapper>
  </b-overlay>
</template>

<script>
import AuthenticatorAppIcon from "@/assets/svg/AuthenticationAppIcon.svg";
import EmailIcon from "@/assets/svg/emailIcon.svg";
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import KiteIcon from "@/assets/svg/KiteIcon.svg";
import TextMessageIcon from "@/assets/svg/TextMessageIcon.svg";
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import TurtleRentLogo from "@core/layouts/components/Logo.vue";
import { required } from "@core/utils/validations/validations";
import { get } from "lodash"
import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BImg,
  BLink,
  BOverlay,
  BRow
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    AuthWrapper,
    KiteIcon,
    AuthenticatorAppIcon,
    EmailIcon,
    BottomDesign,
    TextMessageIcon,
    Rectangles,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      authentication_method: '',
      loading: false,
      resent_account_confirmation_email: false,
      // validation rules
      required
    }
  },
  computed: {
    registeredEmail() {
      return sessionStorage.getItem("registration_email") || "your email"
    }
  },
  beforeRouteLeave() {
    sessionStorage.removeItem("registration_email");
  },
  methods: {
    async resendAccountConfirmationEmail() {
      try {
        this.loading = true;
        await this.useJwt().authService.resendAccountConfirmationEmail({
          username: this.registeredEmail
        });
        this.resent_account_confirmation_email = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Resent Email Confirmation Link',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            text: "Email confirmation link sent successfully."
          },
        });
        this.goToHome();
      } catch (error) {
        this.blogPost = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    goToHome() {
      setTimeout(() => {
        window.location.href = "/auth/login"
      }, 1500)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>
